export const carLoopRecordFormVO = function () {
  return {
    id: null,
    department: {
      id: null,
      name: ''
    },
    carInfo: {
      id: null,
      fullName: ''
    },
    driver: {
      id: null,
      name: ''
    },
    volume: '',
    destination: '',
    recordIds: '', //对应的车辆任务IDS
    recordNames: '', //对应的车辆任务名称
    startTime: '', //开始时间
    endTime: '', //结束时间
    summary: '', //清运概述
    mileage: '', //执行公里
    duration: '', //清运时长
    durationStr: '', //清运时长
    status: '', //执行状态
    recordTrail: {
      id: null,
      position: null
    },
    record: {
      driver: {}
    }
  }
}
