<template>
  <a-skeleton active :loading="ifSub">
    <div class="details">
      <div class="details-title">
        <div class="details-title-text">
          <a-row>
            <a-col :span="22">基础信息</a-col>
            <a-col :span="2">
              <a :style="{ marginLeft: '8px', fontSize: '12px' }" @click="toggle">
                {{expand?'收起':'展开'}}
                <a-icon :type="expand ? 'up' : 'down'"/>
              </a>
            </a-col>
          </a-row>
        </div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo" v-if="expand">
        <a-col :span="8" style="height: 30px;">
          <span>任务状态：</span>
          <a-tag v-if="formItem.record.status === 'NOT'">未开始</a-tag>
          <a-tag v-else-if="formItem.record.status === 'COMPLETED'" color="#108ee9">已完成</a-tag>
          <a-tag v-else-if="formItem.record.status === 'DOING'" color="#87d068">进行中</a-tag>
          <a-tag v-else>-</a-tag>
        </a-col>
        <a-col :span="8" v-if="formItem.record.status === 'DOING' || formItem.record.status === 'COMPLETED'">
          <span>执行司机：</span>
          {{ formItem.record.driver.name || '-' }}
        </a-col>
        <a-col :span="8" v-if="formItem.record.status === 'DOING' || formItem.record.status === 'COMPLETED'">
          <span>执行时间：</span> {{ formItem.record.startTime }} ~ {{ formItem.record.endTime || '至今' }}
        </a-col>
        <a-col :span="8" v-if="formItem.record.status === 'DOING' || formItem.record.status === 'COMPLETED'">
          <span>已耗时：</span> {{ formItem.record.durationStr }}
        </a-col>
        <a-col :span="8" v-if="formItem.record.status === 'COMPLETED'">
          <span>行车公里：</span> {{ formItem.record.mileage }} | {{ formItem.record.modifyMileage }} km
        </a-col>
        <a-col :span="8" v-if="formItem.record.status === 'COMPLETED'">
          <span>排放污水厂：</span> {{ formItem.record.destination }}
        </a-col>
        <a-col :span="24">
          <span>车辆任务：</span>
          <div style="float: left;padding: 0;width: 90%">
            <a-tag style="width: auto;color: white;margin-top: 3px" v-for="(item,index) in reportNames" :key="index" color="#E9967A">{{item}}</a-tag>
          </div>
        </a-col>
        <a-col :span="24" v-if="formItem.record.status === 'COMPLETED'">
          <span>开始位置：</span>{{ formItem.recordTrail.startLocation }}
        </a-col>
        <a-col :span="24" v-if="formItem.record.status === 'COMPLETED'">
          <span>完成位置：</span>{{ formItem.recordTrail.endLocation }}
        </a-col>
      </a-row>
      <div class="details-title" v-if="expand&&images.length&&formItem.record.status === 'COMPLETED'">
        <div class="details-title-text">
          <a-row>清运照片</a-row>
        </div>
        <i class="details-title-text-i"></i>
      </div>
      <a-row class="details-content-rowBasicInfo" v-if="expand">
          <span v-if="images.length&&formItem.record.status === 'COMPLETED'">
              <qiniu-image-upload ref="qiniuImageRef" type="view" :items="images" style="margin-top: -15px"/>
          </span>
      </a-row>
      <div v-if="formItem.record.status === 'DOING' || formItem.record.status === 'COMPLETED'" class="details-title">
        <div class="details-title-text">
          行车轨迹
          <a-button style="margin-left: 20px;" size="small" type="primary" icon="sync" @click="handleRunTrail()">
            轨迹回放
          </a-button>
        </div>
        <i class="details-title-text-i"></i>
      </div>
      <div v-if="formItem.record.status === 'DOING' || formItem.record.status === 'COMPLETED'" class="details-content-rests">
        <div class="cover-show-container" style="margin: 0px;">
          <div :id="loopRecordMapContainerId" class="map-container" :style="{height: styleMapHeight + 'px'}"/>
        </div>
      </div>
      <div class="hide">{{ mapStyle }}</div>
    </div>
  </a-skeleton>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { carLoopRecordFormVO } from './common/carLoopRecordFormVO'
  import UUID from '../../../../utils/UUID'
  import { lazyAMapApiLoaderInstance } from 'vue-amap'
  import '../../../../core/amap'
  import mapStyle from '../../../map/composite/mixins/map-style'
  import xunjianPassed from '../../../map/composite/mixins/xunjian-passed'
  import QiniuImageUpload from '../../../../components/QiniuUpload/QiniuImageUpload'

  export default {
    name: 'LoopRecordDetail',
    components: { QiniuImageUpload },
    mixins: [mapStyle, xunjianPassed],
    data () {
      return {
        images: [],
        expand: false,
        ifSub: false,
        formItem: carLoopRecordFormVO(),
        labelCol: { span: 6 },
        wrapperCol: { span: 17 },
        /*地图*/
        loopRecordMapContainerId: new UUID().id,
        recordMap: undefined,
        marker: null,
        pathArray: null,
        warningArray: null,
        styleMapHeight: 600,
        reportNames: null
      }
    },
    methods: {
      toggle () {
        this.expand = !this.expand
        if (this.expand) {
          this.styleMapHeight = 300
        } else {
          this.styleMapHeight = 600
        }
      },
      /**
       * 初始化地图
       */
      initMap () {
        lazyAMapApiLoaderInstance.load().then(() => {
          this.recordMap = new AMap.Map(this.loopRecordMapContainerId, {
            resizeEnable: true,
            mapStyle: 'amap://styles/normal',
            center: [106.230909, 38.487193],
            zoom: 11
          })
          this.buildMapOtherLayer()
          this.getDevicesOverlayGroup()
          if (
            this.formItem.recordTrail &&
            this.formItem.recordTrail.position &&
            this.formItem.recordTrail.position !== '[]'
          ) {
            this.drawLine(this.formItem.id, this.formItem.recordTrail.position)
            // this.drawWarningPoints()
          }
        })
      },
      getDevicesOverlayGroup () {
        const overlays = []
        // 设置设备覆盖物
        if (this.dbDevicesData) {
          this.dbDevicesData.forEach((item) => {
            let cover = this.buildCover(item)
            let overlay = cover.cover[0]
            if (cover.type === 'text') {
              // 设置 labelLayer 到当前地图中
              this.getLabelLayer(this.recordMap).add(overlay)
            } else {
              overlays.push(overlay)
            }
          })
        }
        this.devicesOverlayGroup = new AMap.OverlayGroup(overlays)
        this.devicesOverlayGroup.setMap(this.recordMap)
      },
      /*加载数据*/
      loadData (id) {
        this.formItem = carLoopRecordFormVO()
        this.$http(this, {
          url: SERVICE_URLS.csgz.carLoopRecordApi.viewDetailAndImage,
          params: { id },
          noTips: true,
          success: (data) => {
            Object.assign(this.formItem, data.body)
            this.reportNames = eval(this.formItem.reportNames)
            this.images = data.body.images
            if (data.body.recordTrail) {
              this.formItem.recordTrail = data.body.recordTrail
            } else {
              this.formItem.recordTrail = {
                id: null,
                position: null,
                startLocation: '',
                endLocation: ''
              }
            }
            this.initMap()
          }
        })
      },
      handleRunTrail () {
        if (
          this.formItem.recordTrail &&
          this.formItem.recordTrail.position &&
          this.formItem.recordTrail.position !== '[]'
        ) {
          this.marker.moveAlong(eval(this.pathArray), 5000)
        }
      },
      /*画线方法*/
      drawLine (id, path) {
        /*默认轨迹*/
        var polyline = new AMap.Polyline({
          path: eval(path),
          strokeColor: '#2d8cf0', //线颜色--蓝色
          strokeOpacity: 1, //线透明度
          strokeWeight: 4, //线宽
          lineJoin: 'round', // 折线拐点连接处样式
          extData: {
            key: id
          }
        })
        this.recordMap.add(polyline)
        var gisArray = eval(path)
        var startPoint = eval(gisArray[0])
        var endPoint = eval(gisArray[gisArray.length - 1])
        let startIcon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(25, 34),
          // 图标的取图地址
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          // 图标所用图片大小
          imageSize: new AMap.Size(135, 40),
          // 图标取图偏移量
          imageOffset: new AMap.Pixel(-9, -3)
        })
        let startMarker = new AMap.Marker({
          map: this.recordMap,
          icon: startIcon,
          offset: new AMap.Pixel(-11, -30),
          position: [startPoint[0], startPoint[1]]
        })
        let endIcon = new AMap.Icon({
          size: new AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new AMap.Size(135, 40),
          imageOffset: new AMap.Pixel(-95, -3)
        })
        let endMarker = new AMap.Marker({
          map: this.recordMap,
          icon: endIcon,
          offset: new AMap.Pixel(-13, -33),
          position: [endPoint[0], endPoint[1]]
        })
        /*回放标记*/
        this.pathArray = path
        this.marker = new AMap.Marker({
          map: this.recordMap,
          position: [startPoint[0], startPoint[1]],
          icon: 'https://webapi.amap.com/images/car.png',
          offset: new AMap.Pixel(-26, -13),
          autoRotation: true,
          angle: -90
        })
        var passedPolyline = new AMap.Polyline({
          map: this.recordMap,
          strokeColor: '#AF5', //线颜色
          strokeWeight: 6, //线宽
          lineJoin: 'round' // 折线拐点连接处样式
        })
        this.marker.on('moving', (e) => {
          passedPolyline.setPath(e.passedPath)
        })
        /*添加到地图*/
        this.recordMap.add(startMarker)
        this.recordMap.add(endMarker)
        this.recordMap.setFitView(polyline)
        this.handleRunTrail()
      },
      /*预警名称获取*/
      getWarningName (item) {
        if (item.typeId === '5') {
          return '断电预警-' + '位置：' + item.location
        } else if (item.typeId === '6') {
          return '低速预警-' + item.gpsStatus + '-位置：' + item.location
        } else if (item.typeId === '7') {
          return '高速预警-' + item.gpsStatus + '-位置：' + item.location
        } else {
          return item.gpsStatus + '预警' + '-位置：' + item.location
        }
      },
      /*画预警点*/
      drawWarningPoints () {
        this.warningArray.forEach((x) => {
          // 创建 AMap.Icon 实例：
          var icon = new AMap.Icon({
            size: new AMap.Size(28, 28), // 图标尺寸
            image: 'http://files.fangshuoit.com/icon_warning_tag.png', // Icon的图像
            imageSize: new AMap.Size(28, 28) // 根据所设置的大小拉伸或压缩图片
          })
          // 将 Icon 实例添加到 marker 上:
          var marker = new AMap.Marker({
            position: new AMap.LngLat(x.longitude, x.latitude),
            offset: new AMap.Pixel(0, 0),
            title: this.getWarningName(x),
            icon: icon,
            angle: 0,
            zoom: 15,
            extData: {
              key: x.id
            }
          })
          marker.setLabel({
            offset: new AMap.Pixel(0, 0), //设置文本标注偏移量
            content: '<div class="car-marker-info">' + x.gpsStatus + '</div>', //设置文本标注内容
            direction: 'top' //设置文本标注方位
          })
          if ((x.longitude !== '0') && (x.latitude !== '0')) {
            this.recordMap.add(marker)
            /*marker.on('click', this.showCarInfo)*/
            this.recordMap.setFitView()
          }
        })
      }
    }
  }
</script>
<style scoped>

  .car-marker-info {
    top: 2px;
    background-color: white;
    right: 0;
    padding: 3px;
    border: 1px solid #c1c2b9;
    border-radius: 4px;
  }

  p {
    margin-top: 0;
    margin-bottom: 0em;
  }
</style>
